<template>
  <div class="disclosure" :class="{ 'l-webscreens': $route.name.includes('webscreens') }">
    <h1>2023 BMW i4 30-minute Charging</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">Effective as of September 2022</p>
    <p>
      Electrify America and BMW are pleased to provide this promotional charging program to drivers of new model year
      2023 BMW i4 vehicles purchased from an authorized BMW dealer. By clicking “Agree” below, you agree to participate
      in this promotion, subject to your compliance with these terms and conditions.
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in the 2023 BMW
      i4 30-minute Charging plan, and acceptance of Terms of Use. Your mobile carrier’s messaging, data, and other rates
      and fees will apply to your use of the Electrify America app.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a 24-month program
      (starting from the date of vehicle purchase) that provides complimentary 30 minutes of DC fast (CCS) charging, and
      additionally, 60 minutes of complimentary Level 2 charging for your i4. You may redeem this credit on Electrify
      America’s network of electric vehicle charging stations.
    </p>
    <p>
      After signing up, you can redeem your complimentary charging during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion. Subject to the
      restrictions below, there is no limit on the number of eligible charging sessions.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the i4 whose VIN you submitted when
      signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others. You may not use this promotion benefit for commercial purposes, including but not limited to, fleet use.
    </p>
    <p>
      Complimentary charging time <strong>does not include</strong> any idle fees or applicable taxes assessed at the
      charging station. You will be responsible for paying such fees through your account if you incur them during a
      charging session. There is a <strong>60 minute waiting period between eligible charging sessions,</strong> and the
      promotion benefits will not apply to a charging session initiated within this period.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the i4 when charging at an
      Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the i4 at the charging station.
    </p>
    <p>
      Once the term of this promotion has terminated or expired, you will not be able to reload, renew, or add more
      credit to this promotional plan. Please refer to the information on the <strong>“Plan Details”</strong> section of
      the app to keep track of the amount of time remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the complimentary charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this program for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any remaining portion of the complimentary charging time. At
      that time, your account will automatically convert to a basic Electrify America “Pass” account, at no additional
      fee or charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up for a different
      type of subscription via the Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: '2023-bmw-i4-30-minute-charging-disclosure',
  metaInfo: {
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/2023-bmw-i4-30-minute-charging-disclosure/' }],
  },
};
</script>
